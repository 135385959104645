import { ingestAPIObject } from "../utils/api";
import dayjs from "dayjs";
import _ from "lodash";
import { ITask } from "./ITask";
import { TaskStatus } from "./TaskStatus";
import { TaskType } from "./TaskType";
export class IrrigationTask extends ITask {
    get id() {
        return this._id ? "irr" + this._id : undefined;
    }
    set id(id) {
        this._id = id;
    }
    get displayDate() {
        return this.scheduledDate || new Date().toISOString().slice(0, 10);
    }
    set displayDate(date) {
        this.scheduledDate = date;
    }
    get status() {
        if (this.completed)
            return TaskStatus.Completed;
        else if (this.activity.length > 0) {
            if (this.activity.every((a) => a.end))
                return TaskStatus.Paused;
            return TaskStatus.InProgress;
        }
        return TaskStatus.Planned;
    }
    set status(status) {
        if (status === TaskStatus.Completed) {
            if (this.activityMinutes === 0) {
                const e = new Error("Please add actual amount watered to complete this task.");
                e.name = "IrrigationActualsError";
                throw e;
            }
            this.completed = true;
            this.activity.forEach((a) => (a.end ?? (a.end = new Date())));
        }
        else if (status === TaskStatus.InProgress) {
            this.completed = false;
            // Start an empty activity if needed
            if (this.activity.every((a) => a.end !== undefined)) {
                this.activity.push(new IrrigationActivity({
                    start: new Date(),
                    taskId: this._id,
                }));
            }
        }
        else if (status === TaskStatus.Paused) {
            this.completed = false;
            this.activity.forEach((a) => (a.end ?? (a.end = new Date())));
        }
        else if (status === TaskStatus.Planned) {
            this.completed = false;
            this.activity = [];
        }
    }
    get allowedStatuses() {
        return [
            TaskStatus.Planned,
            TaskStatus.InProgress,
            TaskStatus.Paused,
            TaskStatus.Completed,
        ];
    }
    get overdue() {
        return (this.status !== TaskStatus.Completed &&
            dayjs().isAfter(dayjs(this.scheduledDate), "day"));
    }
    get icon() {
        if (this.overdue)
            return "fa-solid fa-clock";
        if (this.isAutoScheduled)
            return "fa-solid fa-calendar-clock";
        return TaskStatus.data(this.status).icon;
    }
    get iconTitle() {
        if (this.overdue)
            return "Overdue";
        if (this.isAutoScheduled)
            return "Auto-scheduled";
        return this.status ? TaskStatus.data(this.status).label : undefined;
    }
    get scheduledHours() {
        return this.scheduledMinutes / 60;
    }
    set scheduledHours(hours) {
        this.scheduledMinutes = Math.round(hours * 60);
    }
    get activityMinutes() {
        return _.sum(this.activity
            .filter((a) => a.start != undefined)
            .map((a) => dayjs(a.end).diff(dayjs(a.start), "minutes")));
    }
    set activityMinutes(minutes) {
        const end = new Date();
        const start = new Date(end.getTime() - minutes * 60 * 1000);
        this.activity = [
            new IrrigationActivity({ start, end, taskId: this._id }),
        ];
    }
    get activityHours() {
        return this.activityMinutes / 60;
    }
    set activityHours(hours) {
        this.activityMinutes = Math.round(hours * 60);
    }
    constructor(jsonData) {
        super();
        this.type = TaskType.Irrigation;
        this.title = "Irrigation";
        this.completed = false;
        this.activity = [];
        ingestAPIObject(this, jsonData);
        // Transform dates to datestrings only
        this.scheduledDate = jsonData.scheduledDate?.slice(0, 10);
        this.activity = this.activity.map((a) => new IrrigationActivity(a));
    }
    // TODO: Cache this, and recompute only at reasonable times?
    fcEvent(title, scheduleConfig) {
        const loading = !title;
        return {
            id: this.id || this.virtualId, // TODO: better id handling
            // groupId: TODO: could maybe group linked or batch select?
            title: title || "Loading...",
            allDay: true,
            start: this.scheduledDate, // FIXME: parse date only when needed (future me: what??)
            end: this.scheduledDate,
            color: scheduleConfig?.color,
            textColor: scheduleConfig?.fontColor,
            // constraint: check planting(s)
            durationEditable: false,
            startEditable: (!loading && scheduleConfig?.setPermission) || false,
            extendedProps: {
                icon: this.icon,
                iconTitle: this.iconTitle,
                type: this.type,
                status: this.status,
                task: this,
                subtitle: undefined,
            },
        };
    }
}
export class IrrigationActivity {
    constructor(jsonData) {
        ingestAPIObject(this, jsonData);
    }
}
export var IrrigationMethod;
(function (IrrigationMethod) {
    IrrigationMethod[IrrigationMethod["Drip"] = 1] = "Drip";
    IrrigationMethod[IrrigationMethod["Sprinkler"] = 2] = "Sprinkler";
})(IrrigationMethod || (IrrigationMethod = {}));
