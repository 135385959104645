import { ingestAPIObject } from "../utils/api";
import dayjs from "../utils/dayjs";
import { subscriptNumbers } from "../utils/string";
import _ from "lodash";
import { ITask } from "./ITask";
import { TaskStatus } from "./TaskStatus";
import { TaskType } from "./TaskType";
export class SoilSampleTask extends ITask {
    get id() {
        return this._id ? "ss" + this._id : undefined;
    }
    set id(id) {
        this._id = id;
    }
    get poundsPerAcre() {
        if (!this.sampleDepth || !this.nitratePPM || !this.sampleType)
            return undefined;
        const constant = this.sampleType === SoilSampleType.NO3N ? 2.72 : 0.61;
        return _.round((this.sampleDepth / 12) * this.nitratePPM * constant, 3);
    }
    get displayDate() {
        return (this.collectedDate ??
            this.scheduledDate ??
            new Date().toISOString().slice(0, 10));
    }
    set displayDate(date) {
        if (this.collectedDate || this.completedDate)
            this.collectedDate = date;
        else
            this.scheduledDate = date;
    }
    get status() {
        if (this.completedDate)
            return TaskStatus.Completed;
        else if (this.collectedDate)
            return TaskStatus.Collected;
        return TaskStatus.Planned;
    }
    set status(status) {
        if (status === TaskStatus.Completed) {
            if (!this.collectedDate ||
                this.nitratePPM === undefined ||
                this.sampleType === undefined) {
                throw Error("Can't complete soil sample without results data");
            }
            this.completedDate = new Date().toISOString();
        }
        else if (status === TaskStatus.Collected) {
            this.collectedDate = this.displayDate;
            this.completedDate = undefined;
        }
        else {
            this.completedDate = undefined;
            this.collectedDate = undefined;
            this.scheduledDate ?? (this.scheduledDate = new Date().toISOString().slice(0, 10));
        }
    }
    get allowedStatuses() {
        return [TaskStatus.Planned, TaskStatus.Collected, TaskStatus.Completed];
    }
    constructor(jsonData) {
        super();
        this.type = TaskType.SoilSample;
        this.title = "Soil Sample";
        this.sampleType = SoilSampleType.NO3N;
        ingestAPIObject(this, jsonData);
        // Transform dates to datestrings only
        this.scheduledDate = jsonData.scheduledDate?.slice(0, 10);
        this.collectedDate = jsonData.collectedDate?.slice(0, 10);
        this.completedDate = jsonData.completedDate?.slice(0, 10);
    }
    get isLocked() {
        // Can't move a completed soil sample 24 hours after completion
        return (this.status === TaskStatus.Completed &&
            dayjs.utc(this.completedDate).add(24, "hour") < dayjs());
    }
    // TODO: Cache this, and recompute only at reasonable times?
    fcEvent(title, scheduleConfig) {
        const loading = !title;
        return {
            id: this.id || this.virtualId, // TODO: better id handling
            // groupId: TODO: could maybe group linked or batch select?
            title: title || "Loading...",
            allDay: true,
            start: this.displayDate, // FIXME: parse date only when needed (future me: what??)
            end: this.displayDate,
            color: scheduleConfig?.color,
            textColor: scheduleConfig?.fontColor,
            // constraint: check planting(s)
            durationEditable: false,
            startEditable: (!loading && scheduleConfig?.setPermission && !this.isLocked) ||
                false,
            extendedProps: {
                loading,
                icon: this.icon,
                iconTitle: this.iconTitle,
                type: this.type,
                status: this.status,
                task: this,
                subtitle: undefined,
            },
        };
    }
}
export var SoilSampleType;
(function (SoilSampleType) {
    SoilSampleType[SoilSampleType["NO3N"] = 1] = "NO3N";
    SoilSampleType[SoilSampleType["NO3"] = 2] = "NO3";
})(SoilSampleType || (SoilSampleType = {}));
// eslint-disable-next-line @typescript-eslint/no-namespace
(function (SoilSampleType) {
    function data(type) {
        switch (type) {
            case SoilSampleType.NO3N:
                return {
                    label: SoilSampleTypeLabels[SoilSampleType.NO3N],
                    htmlLabel: subscriptNumbers(SoilSampleTypeLabels[SoilSampleType.NO3N]),
                };
            case SoilSampleType.NO3:
                return {
                    label: SoilSampleTypeLabels[SoilSampleType.NO3],
                    htmlLabel: subscriptNumbers(SoilSampleTypeLabels[SoilSampleType.NO3]),
                };
        }
    }
    SoilSampleType.data = data;
})(SoilSampleType || (SoilSampleType = {}));
const SoilSampleTypeLabels = {
    [SoilSampleType.NO3N]: "NO3-N",
    [SoilSampleType.NO3]: "NO3",
};
